import Vue from 'vue'
import App from './App.vue'
import router from './router' // 引入路由

Vue.config.productionTip = false

// 初始化样式
import '@/common/resetcss.css'

// 引入element
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);



import requset from '@/request/api';

Vue.prototype.$api = requset;


import baseURL from '@/request/baseURL';
Vue.prototype.$uploadURL = `${baseURL}/api/upload/PC_PostFormDataimg`
Vue.prototype.$baseURL = `${baseURL}`;


new Vue({
  router,
  render: function (h) { return h(App) }
}).$mount('#app')
