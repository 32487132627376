<template>
  <div id="container">
    <el-container>
      <el-header style='display: flex;align-items: center;justify-content: space-between;'>
		<h1 style='color: #fff;font-size: 30px;'>国宇售后后台管理系统</h1>
        <!-- 头部信息 -->
        <div style="color: #fff;cursor: pointer" @click="loginOut()">退出</div>
      </el-header>
      <el-container>
        <el-aside width="210px">
          <div style="background-color: #ffffff;color: #333;height: 100%;width: 200px;box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px,rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;">
              <el-menu
                background-color="#ffffff"
                text-color="#5A5A5A"
                router
                active-text-color="#5A5A5A"
                active-background-color="rgba(221, 245, 236, 1)"
                class="el-menu-vertical-demo">
                <div v-for="(item,index) in routerslist" :key="index" >
                  <el-submenu index="1" v-if="item.children.length">
                  <template slot="title">
                    <i class="el-icon-location"></i>
                    <span>{{item.meta.title}}</span>
                  </template>
                  <el-menu-item v-for="(itm,idx) in item.children" :key="idx" :index="itm.path">{{itm.meta.title}}</el-menu-item>
                </el-submenu>
                  <el-menu-item v-else :index="item.path">
<!--                    &&item.path==='adminuser'&&!isusername-->
                    <i class="el-icon-menu"></i>
                    <span slot="title">{{item.meta.title}}</span>
                  </el-menu-item>
                </div>
              </el-menu>
			  <div style="position: absolute;bottom: 10px;font-size: 10px;display: flex;flex-direction: column;align-items: center;">
					<!-- <span>技术支持：©湖北骏意科技有限公司</span>
					<span style="margin-top: 5px;">联系方式：17611027791</span> -->
			  </div>
          </div>
        </el-aside>
        <el-main>
          <div style="width: 100%;height: 100%;overflow: hidden auto;">

            <!-- <el-tabs v-model="editableTabsValue" type="card" editable @edit="handleTabsEdit">
              <el-tab-pane
                :key="item.name"
                v-for="(item, index) in editableTabs"
                :label="item.title"
                :name="item.name"
              >
                {{item.content}}
              </el-tab-pane>
            </el-tabs> -->
            <slot></slot>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import routers from '@/router/Router.ts'
export default {
  data() {
    return {
      editableTabsValue: '2',
      editableTabs: [{
        title: 'Tab 1',
        name: '1',
        content: 'Tab 1 content'
      }, {
        title: 'Tab 2',
        name: '2',
        content: 'Tab 2 content'
      }],
      tabIndex: 2,
	  
    }
  },
  computed:{
    routerslist(){
      const user =  JSON.parse(localStorage.getItem('username'));
      if(!user){
        this.$router.push({
          path: '/login'
        })
        return;
      }
      if(user.username==='GY_ADMIN01'){
        return routers
      }else{
        if(user.BbAtypt=='0'){
          routers=routers.filter(f=>{return f.path!='/baobei'})
        }
        if(user.UbAtype=='0'){
          routers=routers.filter(f=>{return f.path!='/user'})
        }
        return routers.filter(f=>{return f.path!='/adminuser'})
      }
    },
  },
  methods: {
    loginOut(){
      localStorage.removeItem('username');
      this.$router.push({path:'/login'})
    },
      handleTabsEdit(targetName, action) {
        if (action === 'add') {
          let newTabName = ++this.tabIndex + '';
          this.editableTabs.push({
            title: 'New Tab',
            name: newTabName,
            content: 'New Tab content'
          });
          this.editableTabsValue = newTabName;
        }
        if (action === 'remove') {
          let tabs = this.editableTabs;
          let activeName = this.editableTabsValue;
          if (activeName === targetName) {
            tabs.forEach((tab, index) => {
              if (tab.name === targetName) {
                let nextTab = tabs[index + 1] || tabs[index - 1];
                if (nextTab) {
                  activeName = nextTab.name;
                }
              }
            });
          }

          this.editableTabsValue = activeName;
          this.editableTabs = tabs.filter(tab => tab.name !== targetName);
        }
      }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.el-menu-item.is-active {
    background: rgba(221, 245, 236, 1) !important;
}
.el-menu-vertical-demo .is-active::before{
  content: ' ' !important ;
  width: 9px;
  height: 100%;
  background-color: red;
}
  #container{
    height: 100vh;
  }
  .el-header {
    background-color: var(--default_color--);
    color: #333;
    text-align: center;
    line-height: 60px;
  }

  .el-aside {
    background-color: #ffffff;
    color: #333;
    height: 100%;
    // border-right: 1px solid red;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px,rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  }

  .el-main {
    background-color: #fff;
    color: #333;
    // text-align: center;
    // line-height: 160px;
    overflow: auto;
  }

  section {
    // margin-bottom: 40px;
    height: 100%;
    overflow: auto;
  }

</style>
