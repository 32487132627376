/****   http.js   ****/
// 导入封装好的axios实例
import request from './request'

const http = {
    
    /**
     * methods: 请求
     * @param url 请求地址 
     * @param params 请求参数
     */
    async get(url,params,istoken=true){
        const config = {
            method: 'get',
            url:url,
            istoken
        }
        if(params) config.params = params
        // request(config).then(r=>{
        //     console.log(`request(config)${r}`)
        // })
        return await request(config)
    },
    async post(url,params,istoken=true){
        const config = {
            method: 'post',
            url:url,
            istoken
        }
        if(params) config.data = params
        return await request(config)
    },
    put(url,params,istoken=true){
        const config = {
            method: 'put',
            url:url,
            istoken
        }
        if(params) config.params = params
        return request(config)
    },
    delete(url,params,istoken=true){
        const config = {
            method: 'delete',
            url:url,
            istoken
        }
        if(params) config.params = params
        return request(config)
    }
}
//导出
export default http