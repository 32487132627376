// import { Resolver } from 'webpack'
import http from './http'
// 请求
export default{
  // 登录
  PC_GetLogin(params){
    return http.get(`/api/buildings/PC_GetLogin`,params,false)
  },
  // 获取所有管理员
  PC_Getadminlist(params){
    return http.get(`/api/Login/PC_Getadminlist`,params,false)
  },
  // 添加管理员
  PC_GetAddadmin(params){
    return http.post(`/api/Login/PC_GetAddadmin`,params,false)
  },
  // 获取所有协议
  PC_Getgreement(params){
    return http.get(`/api/agreement/PC_Getgreement`,params,false)
  },
  // 添加协议
  PC_PostAdreement(params){
    return http.post(`/api/agreement/PC_PostAdreement`,params,false)
  },
  PC_GetListA(params){
  return http.get(`/api/Kehu/PC_GetKehuchaxunA`,params,false)
},
  // 查询所有报备
  PC_GetListshouye(params){
    return http.get(`/api/Kehu/PC_GetKehuchaxun`,params,false)
  },
  //修改报备状态
  PC_Getupbtypes(params){
    return http.get(`/api/baobei/PC_Getupbtypes`,params,false)
  },
  //查询工单池形信息
  PC_Getbuildinglist(params){
    // return http.get(`/api/buildings/PC_Getbuildinglist`,params,false)
	return http.get(`api/Gongdan/XCX_Getchaxungongdan?`,params,false)
  },
  //修改楼盘
  PC_PostUPbuiings(params){
    return http.post(`/api/buildings/PC_PostUPbuiings`,params,false)
  },
  // 楼盘是否推荐
  PC_GetUpbuilding(params){
    return http.get(`/api/Gongdan/XCX_Getgongdanxiangqing?Gongdanchiid=10`,params,false)
  },
  // 添加楼盘
  PC_GetAddbuildings(params){
    return http.post(`/api/buildings/PC_GetAddbuildings`,params,false)
  },

  // 动态列表
  PC_Getdynamiclist(params){
    return http.get(`/api/dynamic/PC_Getdynamiclist`,params,false)
  },
  // 通过动态审核
  PC_Getdynamic(params){
    return http.get(`/api/dynamic/PC_Getdynamic`,params,false)
  },
//获取用户列表
  PC_GetUsersList(params){
    return http.get(`/api/user/PC_GetUsersList`,params,false)
  },
  //修改权限
  PC_GetRealname(params){
    return http.get(`/api/user/PC_GetRealname`,params,false)
  },
  //修改用户状态
  PC_GetUpuserzt(params){
    return http.get(`/api/user/PC_GetUpuserzt`,params,false)
  },
  //修改后台用户状态
  PC_GetUpadminzt(params){
    return http.get(`/api/buildings/PC_GetUpadminzt`,params,false)
  },
  //实名详情
  PC_Getuserlist(params){
    return http.get(`/api/user/PC_Getuserlist`,params,false)
  },
  //通过审核
  PC_Gethggsdfs(params){
    return http.get(`/api/user/PC_Gethggsdfs`,params,false)
  },
  // 获取统计
  XCX_GetLisAAAtshouye(params){
    return http.get(`/api/user/XCX_GetLisAAAtshouye`,params,false)
  },
  //工单搜索
  XCX_GetchaxungongdanAA(params){
	  return http.get('/api/Gongdan/XCX_GetchaxungongdanAA',params,false)
  },
  //删除工单
  XCX_GetUPgongdanAA(params) {
	  return http.get(`/api/Gongdan/XCX_GetUPgongdanAA`,params,false)
  },
  //删除客户列表
  XCX_GetDelUpKh(params) {
	  return http.get('api/Kehu/XCX_GetDelUpKh',params,false)  
  },
  // 修改显示
  PC_GetKehuchaxunA(params) {
	  return http.get('/api/Kehu/PC_GetKehuchaxunA',params,false)
  },
  //修改
  PC_PostUpKHu(params) {
	  return http.post('/api/Kehu/PC_PostUpK_hu',params,false)
  },
  //获取业务员
  PC_GetUser(params) {
	  return http.get('/api/user/PC_GetUser',params,false)
  }
}
