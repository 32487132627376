const baseURL=
// 'http://39.106.157.44:8082/api'
// 'https://pc.cqxyznyst.com:444/api'
// 'http://erji.cqxyznyst.com'
// 'http://39.105.115.124:65244'

//售后宝
'http://gyshb.guoyuzn.com:8080'
   // 'http://192.168.2.173:8086'
   // 'http://gyshb.guoyuzn.com:8068'
   // 'http://gyshb.guoyuzn.com:8068'
   
// 'http://192.168.2.10:1013/api'
// 'http://192.168.0.102:8089/api'
// 'http://192.168.2.17:199'
export default baseURL;
