<template>
  <div id="app">
    <routerview v-if="IsRouter">
      <router-view/>
    </routerview>
    <router-view v-else />
  </div>
</template>
<script>
  import routerview from '@/components/routerview.vue'
  import routers from '@/router/Router.ts'
  export default{
    components:{
      routerview
    },
    computed:{
      IsRouter(){
        function flatten (arr){
            return arr.reduce((result, item)=> {
                return result.concat(item,(Array.isArray(item.children) ? flatten(item.children) :    []));
            }, []);
        }
        const path = this.$route.path
        return flatten(routers).some(s=>{return s.path==path})
      }
    }
  }
</script>

<style lang="scss">
  body{
    --default_color--:#0BB976;
  }

</style>
