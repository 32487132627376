import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import Routerts from './Router.ts';

function flatten (arr){
  return arr.reduce((result, item)=> {
      return result.concat(item,(Array.isArray(item.children) ? flatten(item.children) :    []));
  }, []);
}
const routes = [
  {
      path:'/login',
      name:'login',
      meta:{
          title:'账号登录'
      },
      component:()=>import('@/views/login.vue')
  },
  {
      path:'/404',
      name:'404',
      meta:{
          title:'404'
      },
      component:()=>import('@/views/404.vue')
  },
  ...flatten(Routerts)
]

const createRouter = () =>{
  // let routeslist=new Array();
    return new VueRouter({
      scrollBehavior: () => ({ y: 0 }),
      routes: [...routes]
    })
}

const router = createRouter()


router.beforeEach((to,from,next)=>{
  // 设置浏览器标签页标题
  window.document.title=to.meta.title||'技术支持：湖北国宇科技有限公司';
  if (to.matched.length === 0) {  // 如果未匹配到路由
    // from.name ? next({ name: from.name }) : next('/404')
    next('/404')
  } else {
    next()  // 如果匹配到正确跳转
  }
  // next()
})

export default router
