/**
 * axios封装
 * 请求拦截、响应拦截、错误统一处理
 */
 import Vue from 'vue';
 import axios from 'axios';
 import router from '../router';
//  import store from '@/store/store';
//  import { Toast } from 'vant';
//  Vue.use(Toast)


import{Message}from 'element-ui';
// Vue.use(onmessage);

import baseURL from './baseURL';
console.log(baseURL)
// const baseURL='https://192.168.2.10:44304/api'
    // 创建axios实例
 const service = axios.create({
    //  baseURL:'https://localhost:44304/api',
     baseURL:baseURL,
    //  baseURL: 'http://39.106.157.44:8080/api',
     timeout: 5 * 1000,
     rejectUnauthorized: false
 })
 service.interceptors.request.use(config=>{
    // store.commit('setappoverlayshow')
     config.headers = {
        
      }
      //注意使用token的时候需要引入cookie方法或者用本地localStorage等方法，推荐js-cookie
    //    const token = getCookie('名称');//这里取token之前，你肯定需要先拿到token,存一下
    //    if(token){
    //       config.params = {'token':token} //如果要求携带在参数中
    //       config.headers.token= token; //如果要求携带在请求头中
    //     }
        // config.params = {'token':'tokenuasdaojdoiadjasdj'} //如果要求携带在参数中
        if(config.istoken){
            let userinfo = localStorage.getItem('login')
            // store.state.userinfo==null && router.push('/login');
            userinfo ==null && router.push('/login');
            config.headers = {
                'Content-Type':'application/json; charset=utf-8', //配置请求头
                token: `${userinfo}`,
                Accept:'application/json'
              }
            //   console.log('要token')
        }else{
            // console.log('不用token')
            config.headers={
                // Accept:'application/json',
                'Content-Type':'application/json;charset=UTF-8' //配置请求头multipart/form-data application/x-www-form-urlencoded
            }
        }
        

    return config
 }, error=>{
     Promise.reject(error)
 })

 service.interceptors.response.use(
    
     response=>{
        // console.log( {data:response.data.Data||{},msg:response.data.msg||''});
        //  console.log(response.data,response.data.msg)
        // store.commit('setappoverlayshow')
        
         switch(response.data.Code||response.data.code)
         {
            case "201":
                // Toast.fail(response.data.msg);
                Message.error(response.data.msg)
                break;
            case "403":
                Toast.fail('未登录');
                router.push('/login')
                break;
            case "200":
                // if(typeof response.data.data == 'boolean'){
                //     if( response.data.data||response.data.Data){
                //         Message({
                //             message:response.data.msg||response.data.Msg,
                //             type: 'success'
                //         })
                //     }
                // }
                // return Promise.resolve(error.response)
                    return response.data
                break;
            case "501":
                Toast.fail('登录状态已失效');
                localStorage.removeItem('login')
                // router.push('/login')
                router.replace('/login')
                break;
         }
        //  switch(response.data.code)
        //  {
        //     case '201':
        //         Toast.fail(response.data.msg);
        //         break;
        //     case '403':
        //         Toast.fail('未登录');
        //         router.push('/login')
        //         break;
        //     case '200':
        //             return response.data;
        //         break;
        //  }
         return false
     },
     error=>{
         console.log(error.response)
        // store.commit('setappoverlayshow')
        console.log('error')
         if(error && error.response){
            console.log('error',error.response.status)
            switch (error.response.status) {
                case 400:
                    error.message = '错误请求'
                    break;
                case 401:
                    error.message = '未授权，请重新登录'
                    break;
                case 403:
                    error.message = '拒绝访问';
                    router.push('/login')                    
                    break;
                case 404:
                    error.message = '请求错误,未找到该资源'
                    // window.location.href = "/NotFound"
                    break;
                case 405:
                    error.message = '请求方法未允许'
                    break;
                case 408:
                    error.message = '请求超时'
                    break;
                case 500:
                    error.message = '服务器端出错'
                    break;
                case 501:
                    error.message = '网络未实现'
                    break;
                case 502:
                    error.message = '网络错误'
                    break;
                case 503:
                    error.message = '服务不可用'
                    break;
                case 504:
                    error.message = '网络超时'
                    break;
                case 505:
                    error.message = 'http版本不支持该请求'
                    break;
                default:
                    error.message = `连接错误${error.response.status}`
            }
         } else{
            // 超时处理
            if (JSON.stringify(error).includes('timeout')) {
                // Message.error('服务器响应超时，请刷新当前页')
                Toast.fail('服务器响应超时，请刷新当前页');
            }
            error.message = '连接服务器失败'
         }
         
         Toast.fail(error.message);
         return Promise.resolve(error.response)
     }
 )
 export default service