export default[
    {
        path:'/',
        name:'home',
        meta:{
            title:'首页'
        },
        component:()=>import('@/views/home/home.vue'),
        children:[
        ]
    },
    {
        path:'/adminuser',
        name:'adminuser',
        meta:{
            title:'管理员'
        },
        component:()=>import('@/views/adminuser/adminuser.vue'),
        children:[
        ]
    },
    {
        path:'/agreement',
        name:'agreement',
        meta:{
            title:'协议列表'
        },
        component:()=>import('@/views/agreement/agreement.vue'),
        children:[
        ]
    },
    {
        path:'/baobei',
        name:'baobei',
        meta:{
            title:'客户列表'
        },
        component:()=>import('@/views/baobei/baobei.vue'),
        children:[
        ]
    },
    {
        path:'/buildings',
        name:'buildings',
        meta:{
            title:'工单列表'
        },
        component:()=>import('@/views/buildings/buildings.vue'),
        children:[
        ]
    },
    {
        path:'/user',
        name:'user',
        meta:{
            title:'员工认证'
        },
        component:()=>import('@/views/user/user.vue'),
        children:[
        ]
    },
    // {
    //     path:'/dynamic',
    //     name:'dynamic',
    //     meta:{
    //         title:'动态'
    //     },
    //     component:()=>import('@/views/dynamic/dynamic.vue'),
    //     children:[
    //     ]
    // },
]
